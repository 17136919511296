import React, { useState } from 'react';
import PropTypes, { func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { LINE_ITEM_DAY, propTypes } from '../../util/types';
import { BookingPanel, IconReviewUser, Modal, PrimaryButton, SecondaryButton } from '../../components';
import { BookingDatesForm, ReviewForm } from '../../forms';

import css from './CancelBookingModal.module.css';
import config from '../../config';

const CancelBookingModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onCancelSale,
    cancelInProgress,
    cancelSaleError,

    unitType,
    currentListing,
    listingTitle,
    bookingSubTitle,
    authorDisplayName,
    onSubmitBookingRequest,
    timeSlots,
    fetchTimeSlotsError,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,

    currentTransaction,
    transactionRole,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'CancelBookingModal.later' });

  const [currentStep, setCurrentStep] = useState(0);


  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <IconReviewUser className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="CancelBookingModal.title" />
      </p>

      {currentStep === 0 &&
        <>
          <p className={css.modalMessage}>
            <FormattedMessage id="CancelBookingModal.beforeCancellation" />
          </p>
          <p className={css.modalTitle}>
            <FormattedMessage id="CancelBookingModal.refundRules" />
          </p>
          <p className={css.modalMessage}>
            <FormattedMessage id="CancelBookingModal.refundRules1" />
          </p>

          <div className={css.buttonsContainer}>
            <SecondaryButton
              className={css.submitButton}
              onClick={(e) => {
                e.preventDefault();
                onCloseModal();
              }}
            >
              <FormattedMessage id="CancelBookingModal.close" />
            </SecondaryButton>

            <PrimaryButton
              className={css.submitButton}
              inProgress={cancelInProgress}
              disabled={cancelInProgress}
              onClick={(e) => {
                e.preventDefault();
                onCancelSale()
                  .then(r => {
                    setCurrentStep(1);
                  })
              }}
            >
              <FormattedMessage id="CancelBookingModal.cancel" />
            </PrimaryButton>
          </div>
        </>
      }

      {currentStep === 1 &&
        <>
          <p className={css.modalMessage}>
            <FormattedMessage id="CancelBookingModal.afterCancellation" />
          </p>

          <SecondaryButton
            className={css.submitButton}
            onClick={(e) => {
              e.preventDefault();
              onCloseModal();
            }}
          >
            <FormattedMessage id="CancelBookingModal.close" />
          </SecondaryButton>
        </>
      }

    </Modal>
  );
};

const { bool, string } = PropTypes;

CancelBookingModal.defaultProps = {
  className: null,
  rootClassName: null,
  cancelSaleErorr: null,
  unitType: config.bookingUnitType,
};

CancelBookingModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onCancelSale: func.isRequired,
  cancelSaleError: propTypes.error,
  cancelInProgress: bool.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default injectIntl(CancelBookingModal);
