import React, { useState } from 'react';
import PropTypes, { func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { LINE_ITEM_DAY, propTypes } from '../../util/types';
import { BookingPanel, IconReviewUser, Modal, PrimaryButton, SecondaryButton } from '../../components';
import { BookingDatesForm, ReviewForm } from '../../forms';

import css from './ModifyBookingModal.module.css';
import config from '../../config';

const ModifyBookingModal = props => {
  const {
    className,
    rootClassName,
    id,
    intl,
    isOpen,
    onCloseModal,
    onManageDisableScrolling,
    onCancelSale,
    cancelInProgress,
    cancelSaleError,

    unitType,
    currentListing,
    listingTitle,
    bookingSubTitle,
    authorDisplayName,
    onSubmitBookingRequest,
    timeSlots,
    fetchTimeSlotsError,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,

    currentTransaction,
    transactionRole,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const closeButtonMessage = intl.formatMessage({ id: 'ModifyBookingModal.later' });

  const [currentStep, setCurrentStep] = useState(0);

  const price = currentListing.attributes.price;

  return (
    <Modal
      id={id}
      containerClassName={classes}
      contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onCloseModal}
      onManageDisableScrolling={onManageDisableScrolling}
      usePortal
      closeButtonMessage={closeButtonMessage}
    >
      <IconReviewUser className={css.modalIcon} />
      <p className={css.modalTitle}>
        <FormattedMessage id="ModifyBookingModal.title" />
      </p>

      {currentStep === 0 &&
        <>
          <p className={css.modalMessage}>
            <FormattedMessage id="ModifyBookingModal.modifyDescription" />
          </p>
          <p className={css.modalMessage}>
            <FormattedMessage id="ModifyBookingModal.modifyDescriptionStep1" />
          </p>
          <p className={css.modalMessage}>
            <FormattedMessage id="ModifyBookingModal.modifyDescriptionStep2" />
          </p>

          <div className={css.buttonsContainer}>
            <SecondaryButton
              className={css.submitButton}
              onClick={(e) => {
                e.preventDefault();
                onCloseModal();
              }}
            >
              <FormattedMessage id="ModifyBookingModal.close" />
            </SecondaryButton>

            <PrimaryButton
              className={css.submitButton}
              inProgress={cancelInProgress}
              disabled={cancelInProgress}
              onClick={(e) => {
                e.preventDefault();
                onCancelSale()
                  .then(r => {
                    setCurrentStep(1);
                  })
              }}
            >
              <FormattedMessage id="ModifyBookingModal.cancelAndModify" />
            </PrimaryButton>
          </div>
        </>
      }

      {currentStep === 1 &&
        // <BookingPanel
        //   className={css.bookingPanel}
        //   titleClassName={css.bookingTitle}
        //   isOwnListing={false}
        //   listing={currentListing}
        //   title={listingTitle}
        //   subTitle={bookingSubTitle}
        //   authorDisplayName={authorDisplayName}
        //   onSubmit={onSubmitBookingRequest}
        //   onManageDisableScrolling={onManageDisableScrolling}
        //   timeSlots={timeSlots}
        //   fetchTimeSlotsError={fetchTimeSlotsError}
        //   onFetchTransactionLineItems={onFetchTransactionLineItems}
        //   lineItems={lineItems}
        //   fetchLineItemsInProgress={fetchLineItemsInProgress}
        //   fetchLineItemsError={fetchLineItemsError}
        // />
        <BookingDatesForm
          className={css.bookingForm}
          formId="BookingPanel"
          submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
          onSubmit={onSubmitBookingRequest}
          price={price}
          unitType={unitType}
          listingId={currentListing.id}
          isOwnListing={false}
          timeSlots={timeSlots}
          fetchTimeSlotsError={fetchTimeSlotsError}
          onFetchTransactionLineItems={onFetchTransactionLineItems}
          lineItems={lineItems}
          fetchLineItemsInProgress={fetchLineItemsInProgress}
          fetchLineItemsError={fetchLineItemsError}
        />
      }

    </Modal>
  );
};

const { bool, string } = PropTypes;

ModifyBookingModal.defaultProps = {
  className: null,
  rootClassName: null,
  cancelSaleErorr: null,
  unitType: config.bookingUnitType,
};

ModifyBookingModal.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  onCancelSale: func.isRequired,
  cancelSaleError: propTypes.error,
  cancelInProgress: bool.isRequired,
  unitType: propTypes.bookingUnitType,
};

export default injectIntl(ModifyBookingModal);
